<template>

    <div v-if="showModal == true">
        <b-popover ref="popover" :show.sync="showModal" :target="targetBtnID"
                   :title="$t('user-list.text.activateAccount') + ' ' + this.accountData.userName " placement="auto">

            <strong>{{ $t('user-list.text.askActivateAccount') }}</strong>

            <div v-if="this.accountData.userSession.lastLogin" class="pt-1">
                {{this.accountData.userName + $t('user-list.text.lleva') + parseLastLogin() + $t('user-list.text.sinHacerLogin')}}
            </div>

            <div class="py-2">
                <b-button :disabled="acceptButtonDisable" style="margin-right: 6px"
                          @click="onOk" size="sm" variant="primary">
                    <b-spinner v-if="acceptButtonDisable" small></b-spinner>
                    <div v-else>{{ $t('buttons.yes') }}</div>
                </b-button>
                <b-button @click="onClose" size="sm" variant="danger">{{ $t('buttons.no') }}</b-button>
            </div>
        </b-popover>
    </div>

</template>

<script>
  import moment from "moment";
  import axios from '@axios'
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { translatableText } from '@/@core/utils/utils';
  import { axiosErrorHandle } from '@core/utils/errorHandler'

  export default {
    name: "ActiveAccount",

    setup(){
      const toast = useToast()
    },

    data(){
      return{
        accountData : null,
        showModal : false,
        targetBtnID : '',
        acceptButtonDisable : false,
      }
    },

    computed:{

    },

    methods: {

      /**
       * Format the user's last login date
       * */
      parseLastLogin(){
        //let date = new Date(this.accountData.userSession.lastLogin).toDateString()
        return moment(this.accountData.userSession.lastLogin).locale('es').fromNow(true)
      },

      /**
       * Displays a toast component with a message
       * @param message (message,icon,variant) message to display in the component , iconName and toast style
       */
      showNotification(message, icon, variant) {
        // alert message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: icon,
            variant: variant,
          },
        })
      },

      /**
       * show the modal
       * @param flag - show or hide the modal
       */
      setShowModal(flag, targetBtnId, accountData){
        this.showModal = false
        this.targetBtnID = targetBtnId
        this.accountData = accountData
        this.showModal = flag
      },

      /**
       * Accept button action
       */
      onOk(){

        this.acceptButtonDisable = true

        axios.post(`${process.env.VUE_APP_URL}/users/activeUser/${this.accountData._id}`, {isActive : true })
          .then(async (response) => {

            this.showNotification(this.$t('user-list.text.userActivated'), 'UserIcon', 'primary')

            this.acceptButtonDisable = false

            this.$emit('refreshUsers')

            this.onClose()

          })
          .catch(async (error) => {
              this.showNotification(await translatableText({text: axiosErrorHandle(error).toString() }) , 'AlertCircleIcon', 'danger')

            this.acceptButtonDisable = false

          })

      },

      /**
       * Cancel button action
       * @param flag - show or hide the modal

       */
      onClose() {
        this.showModal = false
      },

    },

  }
</script>

<style scoped>

</style>
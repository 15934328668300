<template>
  <b-modal
    :visible="userExclusionModalShow"
    bg-variant="white"
    :title="`${$t('user-list.text.excludeUser')} ${userData.userName}`"
    shadow
    backdrop
    disabled=""
    no-close-on-backdrop
    :hide-header-close="loadings.loadingExclusionData"
    hide-footer
    centered
    size="md"
    @hidden="setModal(false, {})"
    @show="resetExclusionData"
  >
    <template #default="{ hide }">
      <div>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col v-if="loadings.loadingExclusionData">
                <div>
                  <b-skeleton animation="fade" width="110px"></b-skeleton>
                  <b-skeleton animation="fade" height="65px"></b-skeleton>
                </div>
                <b-skeleton
                  animation="fade"
                  width="130px"
                  height="20px"
                ></b-skeleton>
              </b-col>
              <b-col v-else>
                <!-- reason -->
                <validation-provider
                  #default="validationContext"
                  name="reason"
                  rules="required"
                >
                  <b-form-group label-for="reason">
                    <template #label> Motivo de la exclusión </template>
                    <b-form-textarea
                      v-model="userExclusion.reason"
                      :state="getValidationState(validationContext)"
                      trim
                      :disabled="userWithSystemExclusion"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- allow withdrawals -->
                <validation-provider
                  #default="validationContext"
                  name="withdrawals"
                >
                  <b-form-group label-for="withdrawals">
                    <b-form-checkbox
                      v-model="userExclusion.withWithdrawal"
                      switch
                      :disabled="userWithSystemExclusion"
                    >
                      {{ $t('user-list.text.permitWithdraw') }}
                    </b-form-checkbox>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-skeleton
                type="button"
                width="120px"
                animation="fade"
                v-if="loadings.loadingExclusionData"
              />
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="userExclusion ? 'primary' : 'danger'"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="loadings.activateAndRevokeButton"
                  type="grow"
                  label="Spinning"
                  class="mr-1"
                  small
                ></b-spinner>
                <feather-icon
                  v-else
                  :icon="
                    userWithSystemExclusion === true
                      ? 'XCircleIcon'
                      : 'FlagIcon'
                  "
                  class="mr-1"
                />
                {{ userWithSystemExclusion === true ? $t('user-list.text.revocar') : $t('user-list.text.activate') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BTab,
  BTabs,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { reactive, ref, watch, getCurrentInstance } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import { required } from "@validations";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import {translatableText} from "@core/utils/utils";
export default {
  name: "UserExclusion",
  components: {
    BForm,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BTabs,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const toast = useToast(ToastificationContent);
    const vm = getCurrentInstance()
    const userExclusionModalShow = ref(false);

    const userWithSystemExclusion = ref(false);

    const userData = ref({});

    const userExclusion = ref({
      _id: "",
      withWithdrawal: false,
      reason: "",
    });

    const loadings = reactive({
      activateAndRevokeButton: false,
      loadingExclusionData: false,
    });

    const setModal = (flag, data) => {
      userExclusionModalShow.value = flag;
      userData.value = { ...data };
    };

    const resetExclusionData = () => {
      userExclusion.value = {
        _id: "",
        withWithdrawal: false,
        reason: "",
      };
      userWithSystemExclusion.value = false;
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetExclusionData);

    const onSubmit = () => {
      if (userWithSystemExclusion.value === true) handleRevoke();
      else handleCreate();
    };

    const handleCreate = async () => {
      try {
        loadings.activateAndRevokeButton = true;

        const payload = {
          userId: userData.value._id,
          approbation: true,
          reason: userExclusion.value.reason,
          withdrawals: userExclusion.value.withWithdrawal,
        };

        await store.dispatch("app-user/createUserExclusionBySystem", payload);

        resetForm();

        toast({
          component: ToastificationContent,
          props: {
            title: vm.proxy.$t('user-list.text.exclusion'),
            text: vm.proxy.$t('user-list.text.exclusionCreated'),
            icon: "FlagIcon",
            variant: "success",
          },
        });
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: await translatableText({text: axiosErrorHandle(error)}),
            icon: "XIcon",
            variant: "danger",
          },
        });
      } finally {
        setModal(false);
        loadings.activateAndRevokeButton = false;
      }
    };

    const handleRevoke = async () => {
      try {
        loadings.activateAndRevokeButton = true;
        await store.dispatch(
          "app-user/deleteUserExclusionBySystem",
          userExclusion.value._id
        );
        toast({
          component: ToastificationContent,
          props: {
            title: vm.proxy.$t('user-list.text.exclusion'),
            text: vm.proxy.$t('user-list.text.exclusionRevocada'),
            icon: "FlagIcon",
            variant: "success",
          },
        });
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: await translatableText({text: axiosErrorHandle(error)}),
            icon: "XIcon",
            variant: "danger",
          },
        });
      } finally {
        loadings.activateAndRevokeButton = false;
        setModal(false);
      }
    };

    const handleGetData = async () => {
      try {
        loadings.loadingExclusionData = true;

        const systemUserExclusion = await store.dispatch(
          "app-user/getUserExclusionBySystem",
          userData.value._id
        );

        if (systemUserExclusion.data) {
          //set control variable
          userWithSystemExclusion.value = true;
          userExclusion.value._id = systemUserExclusion.data._id;
          userExclusion.value.reason = systemUserExclusion.data.reason;
          userExclusion.value.withWithdrawal =
            systemUserExclusion.data.withdrawals;
        }
      } catch (e) {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: await translatableText({text: axiosErrorHandle(e)}),
            icon: "XIcon",
            variant: "danger",
          },
        });
        setModal(false);
      } finally {
        loadings.loadingExclusionData = false;
      }
    };

    watch(userData, (newValue) => {
      if (Object.entries(newValue).length > 0) handleGetData();
    });

    return {
      setModal,
      userExclusionModalShow,
      getValidationState,
      refFormObserver,
      resetForm,
      userData,
      required,
      resetExclusionData,
      onSubmit,
      loadings,
      userExclusion,
      userWithSystemExclusion,
    };
  },
};
</script>

<style scoped></style>

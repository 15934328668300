<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refreshUserMetho()" ref="userListAddNew" /> -->
    <UserListAddNewcopy
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActivecopy"
      @refetch-data="refreshUserMetho()"
      ref="UserListAddNewcopy"
    />
    <HistoryUserList
      :is-history-user-list-active.sync="isHistoryUserListActive"
      ref="HistoryUser"
    />

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :status-options="statusOptions"
    /> -->

    <b-row>
      <b-col md="12" class="mb-2">
        <b-row style="gap: 10px; margin-left: 5px">
          <export-button
            :filter="exportFilter"
            url="/users-export"
            :disabled="loadingListUsers"
            file-title="Users"
          />

          <b-button
            v-if="isSeePermission({ permission: 'import-users-list' })"
            variant="primary"
            class="d-flex align-items-center justify-content-center"
            @click="importFile()"
          >
            <feather-icon icon="UploadIcon" size="16" class="mr-50" />
            <span class="text-nowrap">{{ $t("modal_title") }}</span>
          </b-button>
          <b-button v-b-modal.modal-hierachy v-if="width<768">
            <feather-icon icon="GitPullRequestIcon" />
          </b-button>
        </b-row>
      </b-col>

      <b-col md="9">
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('labels.show') }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('labels.entries') }}</label>
              </b-col>
              <b-col
                md="6"
                class="d-flex align-items-center justify-content-center mb-1"
              >
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('labels.search')"
                />
                <div>
                  <!-- <b-button variant="primary" class="d-flex align-items-center justify-content-center"
                    @click="AddUser()">
                    <feather-icon icon="UserPlusIcon" size="16" class="mr-50" />
                    <span class="text-nowrap">{{ $t('buttons.add') }}</span>
                  </b-button> -->
                  <b-button
                    variant="primary"
                    class="d-flex align-items-center justify-content-center"
                    @click="AddUserCopy()"
                  >
                    <feather-icon icon="UserPlusIcon" size="16" class="mr-50" />
                    <span class="text-nowrap">{{ $t('buttons.add') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="filters">
                  <!--                  <b-button variant="primary" class="d-flex align-items-center justify-content-center"-->
                  <!--                            @click="downloadFile()">-->
                  <!--                    <feather-icon icon="UserPlusIcon" size="16" class="mr-50"/>-->
                  <!--                    <span class="text-nowrap">{{ $t('labels.export') }}</span>-->
                  <!--                  </b-button>-->
                  <b-button
                    :variant="roleFilter == null ? 'danger' : 'light'"
                    size="sm"
                    pill
                    @click="roleFilter = null"
                  >
                    <feather-icon icon="UsersIcon" size="16" class="mr-50" />
                    <span class="text-nowrap">{{ $t('buttons.all') }}</span>
                  </b-button>

                  <div v-for="(role, index) in roleOptions" :key="index">
                    <b-button
                      :variant="roleFilter == role.value ? 'danger' : 'light'"
                      size="sm"
                      pill
                      @click="roleFilter = role.value"
                      v-if="(role.value == 'admin' && $store.getters['user/typeUser'] == 'Root') || (role.value != 'admin')"
                    >
                      <feather-icon
                        :icon="resolveUserRoleIcon(role.value)"
                        size="16"
                        class="mr-50"
                      />
                      <span class="text-nowrap">{{ role.label }}</span>
                    </b-button>
                  </div>

                  <!-- estatus inactive -->
                  <b-button
                    :variant="statusFilter == true ? 'light' : 'danger'"
                    size="sm"
                    pill
                    @click="statusFilter = !statusFilter"
                  >
                    <feather-icon
                      :icon="statusFilter ? 'UnlockIcon' : 'LockIcon'"
                      size="16"
                      class="mr-50"
                    />
                    <span
                      class="text-nowrap"
                      >{{ statusFilter ? $t('buttons.unlocked') : $t('buttons.locked') }}</span
                    >
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-overlay
            :show="loadingListUsers"
            :variant="$store.state.appConfig.layout.skin"
            blur="2"
            class="p-50 table-container"
            opacity="0.50"
          >
            <b-table
              class="user-list-table"
              style="min-height: 300px;"
              ref="refUserListTable"
              :items="listUsers"
              responsive
              :fields="tableColumns"
              primary-key="_id"
              show-empty
              :empty-text="$t('message.no_records_found')"
              @sort-changed="onSortChanged"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
            >
              <!-- Column: User -->

              <template #cell(user)="data">
                <b-media vertical-align="center">
                  <b-link
                    target="_blank"
                    :to="{ name: 'apps-users-show', params: { id: data.item._id } }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.item.userName }}
                  </b-link>
                </b-media>
              </template>

              <template #cell(userFather)="data">
                <b-media vertical-align="center">
                  <b-link
                    target="_blank"
                    :to="{ name: 'apps-users-show', params: { id: data.item.branch._id } }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.item.branch.userName }}
                  </b-link>
                </b-media>
              </template>

              <!-- Column: Role -->
              <template #cell(role)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :icon="resolveUserRoleIcon(data.item.typeUser)"
                    size="18"
                    class="mr-50"
                    :class="`text-${resolveUserRoleVariant(data.item.typeUser)}`"
                    v-b-tooltip.hover.top="data.item.typeUser"
                  />
                  <span
                    class="align-text-top text-capitalize"
                    >{{ data.item.role }}</span
                  >
                </div>
              </template>

              <!-- Column: Upline -->
              <template #cell(upLine)="data">
                <p>
                  {{ upLineName(data.item.upLine) }}
                </p>
              </template>

              <!-- Column: Status -->
              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                  class="text-capitalize"
                >
                  {{ data.item.status ? 'Active' : 'Inactive' }}
                </b-badge>
              </template>

              <!-- Column: currency -->
              <!-- <template #cell(currency)="data">
              <div v-if="data.item.wallet">
                <v-select
                  @input="previewBalance($event, data.item.wallet._id)"
                  :value="data.item.wallet.balance.currency"
                  placeholder="select currency"
                  label="currency"
                  :reduce="(item) => item.currency"
                  :options="data.item.wallet.currencies"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :searchable="false"
                  class="style-chooser"
                  :appendToBody="true"
                >
                </v-select>
              </div>
            </template> -->

              <!-- Column: balance -->
              <template #cell(balance)="data">
                <span v-if="data.item.wallet" class="text-nowrap">
                  {{ selectCurrencyWallet(data.item.wallet.currencies) | currency({symbol: currencySelect}) }}
                </span>
              </template>

              <!-- Column: Transaction -->
              <template #cell(transaction)="data">
                <b-button
                  variant="outline-success"
                  class="btn-icon rounded-circle mr-1"
                  v-b-tooltip.hover.top="$t('tooltips.walletrecharge')"
                  @click="showWalletCreditDebit(data.item, 'credit')"
                  size="sm"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>

                <b-button
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  v-b-tooltip.hover.top="$t('tooltips.walletdebit')"
                  @click="showWalletCreditDebit(data.item, 'debit')"
                  size="sm"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
              </template>

              <!-- Column: Actions -->

              <!--
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item._id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Details</span>
                </b-dropdown-item>

                <div>
                  <b-dropdown-item @click="EditUser(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>
                </div>

                <b-dropdown-item :to="{ name: 'apps-users-wallet',
                    params: { id: data.item._id} }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Wallet to transfer</span>
                </b-dropdown-item>

                <b-dropdown-item @click="showResetPassword(data.item)" >
                  <feather-icon icon="KeyIcon" />
                  <span class="align-middle ml-50">Reset Password</span>
                </b-dropdown-item>

                <div >
                  <b-dropdown-item  @click="removeUser(data.item._id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </div>

              </b-dropdown>
            </template>
            -->

              <template #cell(actions)="data">
                <div class="user-settings">
                  <div
                    class="user-settings-items"
                    :class="{active: userSettings === data.item._id}"
                  >
                    <b-button
                      variant="primary"
                      class="btn-icon rounded-circle"
                      v-b-tooltip.hover.top="$t('tooltips.detail')"
                      :to="{ name: 'apps-users-show', params: { id: data.item._id } }"
                      size="sm"
                    >
                      <feather-icon icon="FileTextIcon" />
                    </b-button>

                    <b-button
                      variant="primary"
                      class="btn-icon rounded-circle"
                      v-b-tooltip.hover.top="$t('send_report')"
                      @click="$router.push({name: 'apps-messages-list',params :{id : data.item._id}})"
                      size="sm"
                    >
                      <feather-icon icon="MessageSquareIcon" />
                    </b-button>

                    <UserFileVerification
                      v-if="data.item.typeUser === 'Player'"
                      :user="data.item"
                      style="display: contents;"
                      @refetch-data="refreshUserMetho()"
                    ></UserFileVerification>

                    <b-button
                      variant="primary"
                      class="btn-icon rounded-circle"
                      v-b-tooltip.hover.top="$t('games_products_block')"
                      @click="showLockProducGames(data.item)"
                      size="sm"
                    >
                      <feather-icon icon="BoxIcon" />
                    </b-button>

                    <!-- <b-button variant="primary" class="btn-icon rounded-circle"
                  v-b-tooltip.hover.top="$t('tooltips.delete')" @click="removeUser(data.item._id)" size="sm">
                  <feather-icon icon="TrashIcon" />
                </b-button> -->

                    <!-- active inactive user  -->
                    <b-button
                      :id="`btn_${data.item._id}`"
                      v-if="!data.item.isActive && data.item.typeUser === 'Player'"
                      variant="primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      v-b-tooltip.hover.top="$t('user_inactive')"
                      @click="showUserActiveModal(data.item, `btn_${data.item._id}`)"
                    >
                      <feather-icon :icon="'InfoIcon'" />
                    </b-button>
                    <!-- delete user  -->
                    <b-button
                      v-if="data.item.typeUser === 'Player'"
                      variant="primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      v-b-tooltip.hover.top="$t('user_delete')"
                      @click="showUserDeleteModal(data.item._id)"
                    >
                      <feather-icon :icon="'TrashIcon'" />
                    </b-button>

                    <b-button
                      v-if="data.item.typeUser === 'Player'"
                      variant="primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      v-b-tooltip.hover.top="$t('user-list.text.excludeUser')"
                      @click="setUserExclusionModal(true, data.item)"
                    >
                      <feather-icon :icon="'FlagIcon'" />
                    </b-button>

                    <!--                  <b-button v-if="data.item.wallet && data.item.wallet.currencies.length > 0"-->
                    <!--                            @click="setClientLimitModal(true,data.item)"-->
                    <!--                            variant="primary" class="btn-icon rounded-circle" size="sm"-->
                    <!--                            v-b-tooltip.hover.top="'Limitar Usuario'">-->
                    <!--                      <feather-icon icon="DollarSignIcon"/>-->
                    <!--                  </b-button>-->

                    <!--<b-button variant="primary" class="btn-icon rounded-circle"
                  v-b-tooltip.hover.top="$t('tooltips.history')" @click="history(data.item)" size="sm">
                  <feather-icon icon="WatchIcon" />
                </b-button>-->

                    <b-button
                      variant="primary"
                      class="btn-icon rounded-circle d-lg-none"
                      v-b-tooltip.hover.top="$t('tooltips.edit')"
                      @click="EditUser(data.item)"
                      size="sm"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>

                    <b-button
                      variant="primary"
                      class="btn-icon rounded-circle d-lg-none"
                      v-b-tooltip.hover.top="$t('tooltips.resetpassword')"
                      @click="showResetPassword(data.item)"
                      size="sm"
                    >
                      <feather-icon icon="KeyIcon" />
                    </b-button>

                    <b-button
                      variant="primary"
                      class="btn-icon rounded-circle d-lg-none"
                      v-b-tooltip.hover.top="data.item.status ? $t('user_block') : $t('user_unblock')"
                      @click="LockUnlockUser(data.item)"
                      size="sm"
                    >
                      <feather-icon
                        :icon="data.item.status ? 'LockIcon' : 'UnlockIcon'  "
                      />
                    </b-button>


                      <b-avatar
                      size="30"
                      src=""
                      @click="setUserForceLogOutModal(true,data.item)"
                      button
                      badge
                      badge-offset="-0.1em"
                      variant="primary"
                      class="text-center badge-minimal d-lg-none"
                      :badge-variant="usersOnlineId.includes(data.item._id) ? 'success' : 'danger'"
                    >
                      <feather-icon icon="UserIcon" size="17" />
                    </b-avatar>
                  </div>

                  <b-button
                    variant="primary"
                    class="btn-icon rounded-circle d-none d-lg-flex"
                    v-b-tooltip.hover.top="$t('tooltips.edit')"
                    @click="EditUser(data.item)"
                    size="sm"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    variant="primary"
                    class="btn-icon rounded-circle d-none d-lg-flex"
                    v-b-tooltip.hover.top="$t('tooltips.resetpassword')"
                    @click="showResetPassword(data.item)"
                    size="sm"
                  >
                    <feather-icon icon="KeyIcon" />
                  </b-button>

                  <b-button
                    variant="primary"
                    class="btn-icon rounded-circle d-none d-lg-flex"
                    v-b-tooltip.hover.top="data.item.status ? $t('user_block') : $t('user_unblock')"
                    @click="LockUnlockUser(data.item)"
                    size="sm"
                  >
                    <feather-icon
                      :icon="data.item.status ? 'LockIcon' : 'UnlockIcon'  "
                    />
                  </b-button>

                  <b-avatar
                    size="30"
                    src=""
                    @click="setUserForceLogOutModal(true,data.item)"
                    button
                    badge
                    badge-offset="-0.1em"
                    variant="primary"
                    class="text-center badge-minimal d-none d-lg-flex"
                    :badge-variant="usersOnlineId.includes(data.item._id) ? 'success' : 'danger'"
                  >
                    <feather-icon icon="UserIcon" size="17" />
                  </b-avatar>
                  <b-button
                    class="btn-icon rounded-circle"
                    size="sm"
                    @click="handleUserSettings(data.item._id)"
                  >
                    <feather-icon icon="MoreVerticalIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-overlay>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >{{ $t('labels.showing') }} {{ dataMeta.from }}
                  {{ $t('labels.to') }}
                  {{
                    dataMeta.to
                  }}
                  {{ $t('labels.of') }} {{ dataMeta.of }}
                  {{ $t('labels.entries') }}</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalUsers"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <UserResetPassword ref="UserResetPassword" />
        <UsersWalletCreditDebit
          ref="UsersWalletCreditDebit"
          @fetchUser="() => refreshUserMetho()"
        />
      </b-col>

      <b-modal
        id="modal-hierachy"
        hide-footer
        title="Listado de agentes"
        v-if="width<768"
      >
        <HierarchyUsers
          @selectUser="selectUserHierarchy"
          ref="HerarchiComponent"
        />
      </b-modal>

      <b-col class="pt-2 p-sm-0" md="3" v-else>
        <b-card no-body class="p-0 py-1">
          <HierarchyUsers
            @selectUser="selectUserHierarchy"
            ref="HerarchiComponent"
          />
        </b-card>
      </b-col>
    </b-row>
    <UserLockProducGames ref="UserLockProducGames" />
    <ActiveAccount
      @refreshUsers="refreshUserMetho()"
      ref="activeAccountComponent"
    ></ActiveAccount>
    <UserLimitationModal ref="clientLimitationComponent" />
    <UserDelete ref="userDeleteComponent" @loadUsers="refreshUserMetho" />
    <UserForceLogOut ref="userForceLogOut" />
    <UserExclusion ref="userExclusion" />
    <UserImport ref="usersAnddWalletsImport"></UserImport>

    <div style="display: none">
      <div id="swl-template">
        <div class="form-check">
          <div class="py-2">
            <input ref="comment" type="text" class="form-control" />
          </div>
        </div>
        <div class="container">
          <button
            @click="doLockUnlockUser(false, $refs.comment.value)"
            type="button"
            class="btn border-0 btn-primary mr-1"
          >
            {{ isPlayer ? $t('buttons.yes') : $t('buttons.blockOnlyOne') }}
          </button>
          <button
            @click="doLockUnlockUser(true, $refs.comment.value)"
            v-if="!isPlayer"
            type="button"
            class="btn border-0 btn-danger ml-1"
            style="display: inline-block;"
          >
            {{ $t('buttons.blockAll') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, computed, getCurrentInstance} from '@vue/composition-api'
import {avatarText} from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import UserListAddNewcopy from './AddUser/UserListAddNewcopy.vue'
import UserWallet from '../users-edit/UsersWallet.vue'
import roleStoreModule from '../../../pages/roles/roleStoreModule'
import UserResetPassword from './UserResetPassword.vue'
import UsersWalletCreditDebit from './UsersWalletCreditDebit.vue'
import HierarchyUsers from '@core/components/HierarchyUsers/HierarchyUsers.vue'
import {useToast} from 'vue-toastification/composition'
import UserLockProducGames from './UserLockProducGames.vue'
import UserFileVerification from './UserFileVerification.vue'
import HistoryUserList from './HistoryUser/HistoryUserList.vue'
import ActiveAccount from "@/views/apps/user/users-list/ActiveAccount";
import UserLimitationModal from "@/views/pages/limitation/userLimitation/UserLimitationModal.vue";
import UserDelete from "@/views/apps/user/users-list/UserDelete.vue";
import {mapMutations, mapState} from "vuex";
import UserForceLogOut from "@/views/apps/user/users-list/UserForceLogOut.vue";
import UserExclusion from "@/views/apps/user/users-list/UserExclusion.vue";
import ExportButton from "@/views/pages/export/ExportButton.vue";
import UserImport from "@/views/pages/whitelabel/user-import/index.vue";
import {useUtils} from "@core/libs/acl";

export default {
  components: {
    UserImport,
    ExportButton,
    UserExclusion,
    UserForceLogOut,
    UserLimitationModal,
    UsersListFilters,
    UserListAddNew,
    UserWallet,
    UserResetPassword,
    UsersWalletCreditDebit,
    HierarchyUsers,
    UserLockProducGames,
    UserListAddNewcopy,
    UserFileVerification,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    VBTooltip,
    vSelect,
    HistoryUserList,
    ActiveAccount,
    UserDelete
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      // tableColumns: [
      //   {key: 'user', sortable: true, label: this.$t('labels.user')},
      //   // userFather
      //   {key: 'userFather', sortable: true, label: this.$t('labels.userFather')},
      //   // { key: 'email', sortable: false },
      //   {key: 'role', sortable: false, label: this.$t('labels.role')},
      //   // { key: 'upLine', sortable: false },
      //   // { key: 'level', sortable: false },
      //   // { key: 'status', sortable: true },
      //   {key: 'balance', label: this.$t('labels.balance')},
      //   {key: 'transaction', label: this.$t('labels.transaction')},
      //   {key: 'actions', label: this.$t('labels.actions')},
      // ],
      isPlayer: false,
      user: {},
      userSettings: null,
      width: 0,
    }
  },

  setup() {
    const {isSeePermission} = useUtils();
    const toast = useToast()
    const vm = getCurrentInstance()
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const ROLE_APP_STORE_MODULE_NAME = 'app-role'
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    })

    store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)


    const isAddNewUserSidebarActive = ref(false)
    const isAddNewUserSidebarActivecopy = ref(false)
    const isHistoryUserListActive = ref(false)
    const isAddNewTransactionSidebarActive = ref(false)

    const statusOptions = [
      {label: 'Active', value: 'true'},
      {label: 'Inactive', value: 'false'},
    ]

    const roleOptions = [
      {label: 'Admin', value: 'admin',},
      {label: 'Agent', value: 'agent',},
      {label: 'Player', value: 'player',},
    ]

    const whitelabelSelect = computed(() => {
      return store.state.whitelabelCurrencyNabvar.whitelabel
    })

    const AddUser = () => {
      if (whitelabelSelect.value.client) {
        isAddNewUserSidebarActive.value = true
      } else {
        // alert message
        toast({
          component: ToastificationContent,
          props: {
            title: vm.proxy.$t('user-list.messages.whiteLabelNotClient'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

      }
    }
    const AddUserCopy = () => {
      if (whitelabelSelect.value.client) {
        isAddNewUserSidebarActivecopy.value = true
      } else {
        // alert message
        toast({
          component: ToastificationContent,
          props: {
            title: vm.proxy.$t('user-list.messages.whiteLabelNotClient'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

      }
    }

    const {
      fetchUsers,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      recordDeleted,
      refetchData,
      userData,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      // Extra Filters
      roleFilter,
      statusFilter,
      listUsers,
      loadingListUsers,
      idUserSelect,
      exportFilter
    } = useUsersList()

    //fetchUsers()

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      isAddNewUserSidebarActivecopy,
      isHistoryUserListActive,
      isAddNewTransactionSidebarActive,
      fetchUsers,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      recordDeleted,
      refetchData,
      avatarText,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      statusOptions,
      roleOptions,
      // Extra Filters
      roleFilter,
      statusFilter,
      userData,
      listUsers,
      loadingListUsers,
      idUserSelect,
      AddUser,
      AddUserCopy,
      whitelabelSelect,
      exportFilter,
      isSeePermission,
    }
  },
  computed: {
    ...mapState('socketIoStore',['usersOnlineId']),

    getSwlTemplate() {
      return document.querySelector('#swl-template');
    },

    currencySelect() {
      return this.$store.state.whitelabelCurrencyNabvar.currency
    },
    tableColumns() {
      return [
        {key: 'user', sortable: true, label: this.$t('labels.user')},
        // userFather
        {key: 'userFather', sortable: true, label: this.$t('labels.userFather')},
        // { key: 'email', sortable: false },
        {key: 'role', sortable: false, label: this.$t('labels.role')},
        // { key: 'upLine', sortable: false },
        // { key: 'level', sortable: false },
        // { key: 'status', sortable: true },
        {
          key: 'balance',
          sortable: true, 
          label: this.$t('labels.balance'),
        },
        {key: 'transaction', label: this.$t('labels.transaction')},
        {key: 'actions', label: this.$t('labels.actions')},
      ]
    },

  },
  watch : {
    listUsers(){
      this.$socket.emit('usersOnline', { userIds: this.listUsers.map(user => user._id) }, (response)=>{
        this.SET_USERS_ONLINE_INITIAL_STATE(response)
      })
    }
  },
  created() {
    this.idUserSelect = JSON.parse(localStorage.getItem("userData"))._id;
    this.width = window.innerWidth;

    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
    });
  },
  methods: {
    ...mapMutations('socketIoStore',['SET_USERS_ONLINE_INITIAL_STATE']),

    onSortChanged({ sortBy, sortDesc }) {
      this.sortBy = sortBy; 
      this.isSortDirDesc = sortDesc;
      this.fetchUsers()
    },

    importFile(){
      this.$refs.usersAnddWalletsImport.showModal(true)
    },

    setUserForceLogOutModal(flag,data){
      this.$refs.userForceLogOut.setModal(flag,data);
    },

    setUserExclusionModal(flag,data){
      this.$refs.userExclusion.setModal(flag,data);
    },

    setClientLimitModal(flag,data){
      this.$refs.clientLimitationComponent.setModal(flag,data);
    },

    downloadFile() {
      const VUE_APP_URL = process.env.VUE_APP_URL;
      const downloadFile = VUE_APP_URL + "/media/exports/excel/users.xlsx"
      window.open(downloadFile, "_self")
    },

    EditUser(user) {
      this.$refs.UserListAddNewcopy.methoEditUser(user)
      this.isAddNewUserSidebarActivecopy = true
    },

    history() {
      this.isHistoryUserListActive = true
    },

    upLineName(id) {
      store.dispatch('app-user/fetchUser', {id: id})
          .then(response => {
            return response.data.data.userName
          })
    },

    walletTransactionSidebar(item) {
      this.userData = item
      this.isAddNewTransactionSidebarActive = true
    },

    removeUser(Id) {
      store.dispatch('app-user/removeUser', {id: Id})
          .then(() => {
            this.recordDeleted = !this.recordDeleted
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('user-list.messages.userRemoved'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
    },

    showResetPassword(userSelect) {
      this.$refs.UserResetPassword.showModal(userSelect)
    },

    LockUnlockUser({_id, status, typeUser, isUserIntoExclusionList = false}) {

      if(isUserIntoExclusionList){
        this.$swal.fire({
          title: this.$t('Error'),
          text : this.$t('user-list.messages.userUnclockInExclusionList'),
          showConfirmButton: false,
          icon: "info",
          showCloseButton: true,
          showCancelButton: false,
        })
        return
      }

      this.isPlayer = typeUser === 'Player'
      this.user = {_id, status, typeUser}

      let msg = this.$t('user-list.messages.lockUser2_1');
      if (this.isPlayer) msg = this.$t('user-list.messages.lockUser2_2');

      this.$swal.fire({
        title: status ? this.$t('user-list.messages.lockUser1') + msg : this.$t('user-list.messages.lockUser2') + msg,
        html: this.getSwlTemplate,
        showConfirmButton: false,
        icon: "warning",
        showCloseButton: true,
        showCancelButton: false,
      })
    },

    showLockProducGames(userSelect) {
      this.$refs.UserLockProducGames.user = userSelect
      this.$refs.UserLockProducGames.modalShow = true
    },

    showWalletCreditDebit(userSelect, typeTransaction) {
      this.$refs.UsersWalletCreditDebit.showCreditDebit(userSelect, typeTransaction)
    },

    async previewBalance(currency, walletId) {
      this.listUsers.forEach(user => {
        if (user.wallet?._id == walletId) {
          user.loadingWalletBalance = true
          user.wallet.balance.currency = currency
        }
      })
      const response = await store.dispatch('app-user/fetchBalanceWallet', {currency, walletId})
      this.listUsers.forEach(user => {
        if (user.wallet?._id == walletId) {
          user.loadingWalletBalance = false
          user.wallet.balance.currency = currency
          user.wallet.balance.balance = response.balance
        }
      })
    },

    selectUserHierarchy({id}) {
      this.idUserSelect = id
    },

    refreshUserMetho() {
      console.log("refreshUserMetho")
      this.fetchUsers()
      this.$refs.HerarchiComponent.asyncLoad()
    },

    selectCurrencyWallet(currencies) {
      const balanceCurrency = currencies.find(currency => currency.currency == this.currencySelect)
      return balanceCurrency?.balance
    },

    showUserActiveModal(data, btnID) {
      this.$refs.activeAccountComponent.setShowModal(false, btnID, data)
      setTimeout(() => {
        this.$refs.activeAccountComponent.setShowModal(true, btnID, data)
      }, 10)
    },

    checkComment(value) {
      if (value == "" || value.length <= 4) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('user-list.messages.commentMoreThan5'),
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return false;
      } else {
        return true;
      }

    },

    async doLockUnlockUser(many, value) {
      if (!this.checkComment(value)) {
        return;
      }

      const confirm = await this.$swal({
        title: this.$t('are_your_sure'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('buttons.yes'),
        cancelButtonText: this.$t('buttons.no'),
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger ml-1",
        },
      })

      this.$refs.comment.value = ''

      if (!confirm.value) {
        this.$swal.close()
        return
      }

      const obj = {
        id: this.user._id,
        status: !this.user.status,
        whitelabel: this.whitelabelSelect._id,
        currency: this.currencySelect,
        userBlokId: this.idUserSelect,
        comment: value,
        isAll: many
      }

      const response = await store.dispatch('app-user/lockUnlockUser', obj)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.user.status ? this.$t('user-list.messages.userLock') : this.$t('user-list.messages.userUnLocked'),
          icon: 'EditIcon',
          variant: 'success',
        },
      })

      this.fetchUsers()
      this.$swal.close()
    },

    showUserDeleteModal(userId) {
      this.$refs.userDeleteComponent.setShowModal(userId);
    },

    handleUserSettings(userId) {
      this.userSettings = this.userSettings === userId ? null : userId
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/mixin.scss';

.table-container{
  overflow: auto;
}

.user-list-table{
  overflow: visible;
}

.per-page-selector {
  width: 90px;
}
.filters{
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.user-settings {
  position: relative;
  display: flex;
  gap: 0.2rem;


  &-items {
    position: absolute;
    top: 50%;
    transform: translate(10px, -50%);
    right: calc(100% + 10px);
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    align-items: center;
    gap: 0.2rem;
    background-color: #fff;
    filter: drop-shadow(0 0 10px rgba(183, 192, 206, 0.5));
    border-radius: 0.25rem;
    padding: 0.5rem;

    @include r(sm) {
      display: flex;
    }

    $h:10px;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%,-50%)  rotate(45deg);
      width: $h;
      height: $h;
      background-color: white;
    }

    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: translate(0, -50%);
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.style-chooser .vs__search {
  padding-right: 0;
  padding-left: 0;
}

.style-chooser .vs__dropdown-menu {
  border: 1px solid #b5b5b5;
}

.style-chooser .vs__open-indicator {
  display: none;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem 1.3rem;
}

.swal2-close {
  outline:none;
  box-shadow: none !important;
}
</style>
